export const allowlistAddresses = [
  ['0x1fa6096f902220528b42963a84d171e4de67ac85', 9],
  ['0xb2f0b39ffde1744cdaa2ad8f02aea1775408e563', 50],
  ['0x930beb5551e34fb2e154436d76b26fa10aa8b697', 3],
  ['0xad31d1bf7bab5fe9140738abf2be2c2b9db43ce3', 3],
  ['0x9f56bff4459a58488d76df9e42a1c8fe48046f7d', 10],
  ['0x3a6840528de52824b63d3392af4eda3f069a2037', 3],
  ['0x33d647aa21bd385d68c18c20c6c6102d920969e6', 6],
  ['0x5eb0e091161237b8b0fb353d1e5653a279cbd871', 3],
  ['0x935cf98b5c9a5a4541386d5f0eda999a0970026a', 50],
  ['0x054318d874ac9a647dc057572248f2b40ca9ff1b', 4],
  ['0x07a3460f625e5e6d760ba33134e50ecd38c0f496', 2],
  ['0xcdaf9c60de6e99cea2d8745bf5ea60ad1d9d5ca8', 2],
  ['0xcaee30eb79974543b119f5e257aee3bb68599cda', 3],
  ['0xebc3e6073cee3f70218093c0a0b6c4697f429ef1', 2],
  ['0x162eae74297082d99631ce179f087d3001d6717d', 2],
  ['0x26ada61ac0fb19ad8cc6a6de26c7d388850ec0d3', 2],
  ['0x0e594cec98eee0a21edad5c5b98e1cfac170cede', 2],
  ['0x85e7fa0f1dfc325b3e496f25f2f0dc17998829f0', 4],
  ['0x6d0b7e7e27f023b9f281291386c2e307cd366042', 3],
  ['0xf6ce9b5df69c506ecb96d5c9d955c99fd955a5c3', 2],
  ['0x1cbc2eb477679b0161cd35072d7ea530c5cec313', 2],
  ['0x3944d44ce06588b1fd129550a4470a1ff425555e', 2],
  ['0x1dfae0b3722a1c620f53b77cf5f35e36189924fd', 2],
  ['0x51a061fc58ef50929c1f08efc0ca61dcc8a7a5de', 2],
  ['0x020e298a4c6c38d8d03e273f9c9c04ab1b102e17', 3],
  ['0x11a3c12c8e6672f8504c0cbf9f9165093ee97b4e', 1],
  ['0xbdba2bf81c6cee8d8e7365ef4cbcaedf33c80329', 1],
  ['0x107438df04b34b3ef3d2947f5b346e5c5d4015bb', 1],
  ['0xdb3c6f60ae2560001840511d3261c6278ab17369', 1],
  ['0x1d55342eddbb7104d522c50ec29f8301a05adf51', 1],
  ['0x4fa388a3de073c50db01865580b7deb875b2e5c2', 1],
  ['0x882d23171f35e9620ddec45b54f56456f85fc613', 1],
  ['0x4b7abe648e92c7913de0330240236aa6027418ef', 2],
  ['0xe70bef8f4336a39d4e1be33bf44e611404bf8cad', 1],
  ['0x8f706b286a809f080932354c574e5534c8025f2b', 1],
  ['0xfd73a8dbb4c263230c5f4d1cf33344289268290c', 1],
  ['0xb812d66fb8715539cd4c1d0ef3bc237f37ed5001', 1],
  ['0xa82e1fb81c621ed289a3a37f2e7a47164917e426', 1],
  ['0x36c383b8a18a299761f4a073a8802729ff62965d', 1],
  ['0xfcd49aa97b91c3575d36c95a1fe97dabec017890', 1],
  ['0xfe1532eb9a71431b205e5cdc16cb3c009b948d93', 1],
  ['0xb1da855d5f636a2c8dd923cef543ae89c3c154d5', 1],
  ['0x8e82905bb25a221262ba6f90df2ba047b44357c9', 1],
  ['0x00687b375430cb84a70b5c5e1adba641aef5d1f9', 1],
  ['0x55b239003197ec0f57b6acc1208503f9cdeb1c4d', 1],
  ['0x7711e4b67465c55493a1ca9f4c1f0584014034a9', 1],
  ['0x9bff037e48cf829be50b5212c798b2de84648eef', 1],
  ['0x50177f011a7d7c60c662db9b41e5ba567bf05263', 1],
  ['0xd989999a0444fb29f886c304af5bc1185bf865e4', 1],
  ['0x4bd5a567d7ee2d8ed53248878fb202f68f30d8b6', 1],
  ['0x9cc1d4af4bd2f9123e66433313be82afa802393f', 1],
  ['0xeb7a63737f75cd9b08829b57e7ea52803a221cf3', 1],
  ['0x04748073cf0d89ec795ad10b5d3296de8f35dffc', 1],
  ['0x86eb8be678274ba4c47af17fa8511c3f9c631bbc', 1],
  ['0xa95e189d18b722d04f9a19c55f25383c58d5060c', 1],
  ['0x7d3d915709383e8e267ec3f20b5736717bfc7062', 1],
  ['0x5902675ae1180b0fd18fe566ab9a76d2a9f46592', 1],
  ['0x866e6b8c7fa693ce981862e3e6cb8c5831909ec7', 1],
  ['0xdfd1cce55b5049a4ade1826d776af229ef318cab', 1],
  ['0x5d61f268eef978c27d56fc2722111481e6ae21ef', 1],
  ['0x53c8f1ccbfc160f15a7270ce32af8443ed0f6bb4', 1],
  ['0x16c0be20bb68a5930c7176f2f42ca82346b3246f', 1],
  ['0x805cf5383646ecebeeec89f1bd9439a27e01fe45', 1],
  ['0x18293dfcabc329c6a47db7ab2ca823aa805b9ef1', 11],
  ['0x80e8e15360bf3892751c9916e3862dd5e412ffa9', 1],
  ['0x669b9dd78427abff9a7372b82ccf9e6f36459490', 1],
  ['0xaacb0cc990da7f9aeae32d9a652c3e0ae1647b5f', 1],
  ['0x7696ec0e7924ac4b40f6893a68c0f26b88ca7b58', 1],
  ['0x2c8fe2f1d0485d531e724df52f8766a05867ac6b', 1],
  ['0xeafb25cf3ca2e26e2ffbb825f2f50b10f7f32605', 2],
  ['0x68f7f74a4ab011a7c7749c9ab5f55a148624a8a6', 1],
  ['0x5b29146e5c3e220474bf9d70998ab7ba43fca9df', 1],
  ['0xb6b5c946709386ffd493df562036d8435f74fda1', 1],
  ['0x09c6f773c1cdff63434bebcdb8ece0f37d249121', 1],
  ['0xdf86fdd7cbe4bed371e5278d25e3a3ee5d8f56e7', 1],
  ['0x8871d265a0c8df287cf9a76dd8f6ba513dfda3b9', 1],
  ['0x3e4e10718764b167db326859f552cd11cd873d72', 3],
  ['0x365c3e51bdc17f6c8b69073b9d11aa3d297a5ac8', 1],
  ['0x2d917b19ccf3d97cd25aa336e579521e2e11bb49', 1],
  ['0xcb7711fd43d9a1dc333c4024688f58f404534b02', 1],
  ['0x93cf1eab24e5208934167df5b2fa21be4c4150a1', 1],
  ['0xb874a00db7e72856b4f3fed3fc6ebbfc14e864bc', 1],
  ['0xf715e2ed376fa0a4e57729189758bf94dc730e35', 1],
  ['0x30f32804f8f4de20be1a1bf87942bfa0a62ae56f', 1],
  ['0x578499dc47a8b4fc910fa7cb333965056413ab61', 1],
  ['0x1d5653a0b3d2be9325552d5d6837aa1f0fce0b9e', 1],
  ['0x095d2c4d5c9412a647ebc52afc10ab021ae51aa3', 1],
  ['0x9956696cf68978d55203f9d6b49464556a5cb8e2', 1],
  ['0x979abf68cd43598ca1ef272862b921af75f7f874', 1],
  ['0x869e0ec987068fafce9a4a14f480ab1eefc17fee', 1],
  ['0xdf60b29cd0d7ade76c94337bded6325d7194bfaa', 1],
  ['0x816978c6cc3eede8823aed2b1d2db904505bea4b', 1],
  ['0x751e0e4457df078563b7be94f5ba9f97a36a4f55', 1],
  ['0x7dea578b1b46bcca873251eb54a0543b46b749a6', 1],
  ['0x417487275239e0f6c5ac79f5d60135ecf118169b', 1],
  ['0xb5c3c3974be098c0437020f36a40713b7bca3266', 1],
  ['0x7936910b8a03bf9ac7bcef3bb3efbb417a41f0ba', 1],
  ['0xcd29a73e18395ad3f682e641121d8b28b410229b', 2],
  ['0xc7a2d165f8da6db2eed1fa02503d918022f399f1', 1],
  ['0xc999e34971211f5d77b854d5aed4209c93f5a7db', 1],
  ['0x4d25908e3597c647e1f03fe9fa687e104b9d27b4', 1],
  ['0xea79153254ab6446032d0e3d424cca1d94b0eb10', 1],
  ['0x55617ac242b5571e52463aa54a0f69d2c05a69cc', 1],
  ['0x6391278193c33c8e2860f87a759cc45bdf5d0239', 10],
  ['0xeabb8945bf334c05144a13df75eb76d047a7eebd', 1],
  ['0xf406aed3369614123eb6c8ea6afb12995d49dbdb', 1],
  ['0x83fde60b198316091b5c342a2901e8d57fd106ad', 1],
  ['0x6f7080e09395a0c629418c614b4ada71ac5583ba', 1],
  ['0x75a158556579e4c2b6a23603253aeb554200fe09', 1],
  ['0x92505676b6980207c08725acd85d8a491fa6b194', 1],
  ['0x612ca623e264782e3eae3e6efd589c2ff5a2b51c', 1],
  ['0x74bf20a7bfaeed8667c50e8b0d3368b3f80abde1', 10],
  ['0x018dc1b2ee871fbf2804ab67c11596f3099913c7', 50],
  ['0x2d976a1a4c27cd7da4c49a8d775a0f85bbac566d', 20],
  ['0xbd4df4469bc16a1e3de24726255c84e8f1b70363', 20],
];
